.setup{
    height: 100%;
    padding: 100px;
    & > .container{
        position: relative;
        .ant-steps-vertical{
            margin: 20px;
            // .ant-steps-item-wait > .ant-steps-item-content{
                //     & > .ant-steps-item-title, & > .ant-steps-item-description{
                    //         color: rgba(255, 255, 255, .4)
                    //     }
                    // }
                    // .ant-steps-item-process > .ant-steps-item-content{
                        //     & > .ant-steps-item-title, & > .ant-steps-item-description{
                            //         color: rgba(255, 255, 255, 1)
                            //     }
                            // }
                            // .ant-steps-item-finish > .ant-steps-item-content{
                                //     & > .ant-steps-item-title, & > .ant-steps-item-description{
                                    //         color: rgba(255, 255, 255, .7)
                                    //     }
                                    // }
                                }
                                /*.left-panel{
                                    background-color: rgba(0,0,0, .3);
                                    border: 1px solid #52515f;
                                    border-radius: 3px;
                                }*/
        .top-bar{
           height: 15px;
           width: 100%;
           background-color: rgb(255, 102, 0);
           border-right: 1px solid #4b4a57;
           border-left: 1px solid #4b4a57;
           box-sizing: border-box;
        } 
        .panels{
            background-color: rgba(255,255,255, .95);
            border: 1px solid #52515f;
            border-top: 0px;
            border-radius: 0px 0px 3px 3px;
            height: 100%;
            padding-bottom: 2px;
            max-height: 850px;
        }
        .scroller .scroller-body{
            padding-top: 20px;
            padding-right: 20px;
        }
        .ant-steps-item-process > .ant-steps-item-tail:after{
            background-color: #afafaf;
        }
        width: 100%;
        max-width: 1000px;
        max-height: 860px;
        margin: 0px auto;
    }
}