.ant-input.duration-text-input{
    width: 65px;
    margin-left: 20px;
}
.duration-text{
    width: 65px;
    margin-left: 32px;
    margin-top: 6px;
}
.duration-close{
    position: absolute;
    top: -30px;
    right: -7px;
    cursor: pointer;
}
.duration-close:before{
    content: "\E633";
    display: block;
    font-family: "anticon" !important;
}