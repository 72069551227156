.snap-list{
    &.ant-card{
        border-left: 0px;
        border-bottom: 0px;
        border-top: 0px;
    }
    .ant-card-body
    .ant-list-item:hover{
        background-color: rgba(0,0,0, .11);
    }
    .ant-list-item.selected{
        background-color: #127ed6;
        color: #fff;
    }
    .ant-list-item{
        background-color: #fff;
        -webkit-transition: background-color .3s;
        transition: background-color .3s;
        transition-property: background-color;
        transition-duration: .3s;
        transition-timing-function: ease;
        transition-delay: 0s;
        cursor: pointer;
    }
    .full-height{
      height: 100%;
    }
    .ant-list{
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      top: 65px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 48px;
      border: 0px;
    }
}
.create-new{
    -webkit-transition: color .3s;
    transition: color .3s;
    transition-property: color;
    transition-duration: .3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    height: 53px;
    line-height: 53px;
    padding-left: 1em;
    border-top: 1px solid #e8e8e8;
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    cursor: pointer;
    &:hover{
        color: #127ed6;
    }
}