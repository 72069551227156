.ant-layout-content{
  .section-content{
    margin: 24px;
    background-color: #fff;
    .section-content-body{
      padding: 24px;
    }
  }
}
.section-content{
  .section-content-body{
    border: 1px solid #dadada;
    border-top: 0px;
  }
  .section-content-header{
    background-color: #332e59;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #cac6e9;
    text-align: center;
  }
}
.section-content.no-padding{
  padding: 0px;
  .section-content-body{
    border: 1px solid #dadada;
    border-top: 0px;
    padding: 0px;
    margin: 0px;
  }

  &.header{
    .section-content-body{
      position: absolute;
      top: 53px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      //padding-top: 48px;
      box-sizing: border-box;
    }
  }
}

.section-content.no-margin-right{
  margin-right: 0px;
}
.section-content.no-margin{
  margin: 0px;
  height: 100%;
}

.section-content .orange-top{
  background: #ff7601;
  height: 5px;
}