.messages{
    .container{
        height: 100%;
        padding-bottom: 253px;
        width: 100%;
        padding-right: 18px;
        position: relative;
    }
    .ant-input{
        resize: none;
        height: 100%;
        margin-bottom: 10px;
    }
    .footer{
        position: absolute;
        bottom: 0px;
        right: 0px;
        left: 0px;
        border-top: 1px solid #e8e8e8;
        height: 253px;
        padding: 10px; 
        padding-bottom: 54px;
        background-color: #fff;
    }
    .message{
        border: 1px solid;
        padding: 1em;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    .from{
        width: 100%;
        padding-right: 30%;
        .message{
            border-color: #fff;
            background-color: #eee;
        }
    }
    .to{
        width: 100%;
        padding-left: 30%;
        .name{
            text-align: right;
            color: #999;
        }
        .message{
            border-color: #eee;
            background-color: #ccc;
        }
    }
    .details{
        font-weight: 600;
        color: #5d5d5d;
        margin-top: .5em;
    }
}
.message-scroller{
    display: contents;
}