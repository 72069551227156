@import "./react-big-calendar/index.less";
.appointment-length-container{ 
    position: relative;
    width: 100%;
    height: 60px;
    margin-top: 10px;
    .appointment-length-indicator{    
        position: absolute;
        display: inline-block;
        margin-top: 20px;
        height: 10px;
        .appointment-length-number{
            position: absolute;    
            top: -25px;
            right: -5px;
        }
    }
    .appointment-length-contents{
        cursor: pointer;
        position: absolute;
        display: inline-block;
        margin-top: 20px;
        border: 1px solid #522D56;
        background-color: #8E4896;
        color: white;
        border-radius: 4px;
        height: 25px;
        &:hover{
            background-color: #b25bbc;
        }
        .appointment-length-contents-label{
            position: absolute;
            right: 20px;
        }
    }
    .appointment-length-contents.off-color{
        border: 1px solid #221f3c;
        background-color: #332e59;
        &:hover{
            background-color: #463f79;
        }
    }
}
.new-durations-btn{
    margin-top: 20px;
    margin-bottom: 20px;
}
.appointments {
    .ant-layout .calendar-admin-select{
        border-radius: 0px;
        div.ant-select-selector{
            border-radius: 0px;
            border: 0px
        }
    }
    .rbc-calendar{
        height: calc(100% - 38px;)
    }
}
.section-content-body .rbc-calendar .rbc-time-header.rbc-overflowing{
    margin-right: 0px !important;
}