@import './variables.less';

@active-background: @btn-background-color;
@active-border: @btn-background-color;

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 10px;
  background-color: #332e59;
  color: #cac6e9;

  .rbc-toolbar-label {
    flex-grow:1;
    padding: 0 10px;
    text-align: center;
  }

  & button {
    color: @btn-color;

    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: @btn-background-color;
    border: @btn-border-width solid @btn-border;
    height: 32px;
    padding: 0px 15px;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
    transition-delay: 0s;
    transition-duration:0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);

    &:active,
    &.rbc-active {
      background-image: none;
      //box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      //border-color: @btn-highlight;
      color: @btn-highlight;

      &:hover,
      &:focus {
        color: @btn-highlight;
        //border-color: @btn-highlight;
        color: @btn-highlight;
      }
    }

    &:focus {
      color: @btn-highlight;
      //border-color: @btn-highlight;
      color: @btn-highlight;
      outline: -webkit-focus-ring-color auto 0px;
    }

    &:hover {
      color: @btn-highlight;
      //border-color: @btn-highlight;
      color: @btn-highlight;
      cursor: pointer;
    }
  }
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;

  button:not(:last-child) {
    border-right: @btn-border-width solid #3C3764;
  }

  > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  .rbc-rtl & > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rbc-rtl & > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  button::first-letter {
    text-transform: uppercase;
  }

  button + button {
    //margin-left: -1px;
  }

  .rbc-rtl & button + button {
    margin-left: 0;
    margin-right: -1px;
  }

  & + &,
  & + button {
    margin-left: 10px;
  }
}
