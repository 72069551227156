.operations-sync-type{
    color: #656565;
}
.operations-sync-name{

}
.operations-sync-status{
    float: right;    
}

.evet-worker-status{
    label{
        margin: 0px 1em;
    }
}



.ant-pagination-next .ant-pagination-item-link, .ant-pagination-item-link {
    padding-top: 9px
}