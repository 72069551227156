.app-header.ant-layout-header {
  padding: 0 24px;
  .logo {
    color: #ffffff;
    float: left;
    line-height: 64px;
  }
  .ant-menu {
    border-bottom: 0px;
    float: right;
  }
}