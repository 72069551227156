.ant-card.clickable-card{
    &:hover{
        cursor: pointer;
        border-color: #369be3;
        color: #369be3;
    }
}
.appointment-restriction-minimize{    
    position: absolute;
    top: 0;
    right: 0;
    font-size: 15px;
    cursor: pointer;
}

.restriction-appointment-type-header{
    border-bottom: 1px solid #595959;
    margin-bottom: 5px;
}

.restriction-loading-spinner{    
    height: 50px;
    margin-top: 100px;
    width: 100%;
    text-align: center;
}