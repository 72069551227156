@admin-layout-sidebar: #1f1b2b;
@admin-highlight:#332e59;
.admin-page {
  .ant-layout-sider-collapsed{
    .organizationName{
      text-align: center;
    }
  }
  .add-organization-icon{
    position: absolute;
    font-size: 20px;
    cursor: pointer;
    right: 15px;
    top: 73px;
  }
  .admin-sider {
    .logo {
      padding-left: 9px;
      line-height: 64px;
      height: 64px;
      background-color: #ff7601;
      display : flex;
      align-items : center;
      img {
        height: 32px;
        display: inline-block;
      }
      h1{
        font-size: 20px;
      }
      h2{
        font-size: 17px;
        margin: 0 0 0 1px;
      }
      h2.n{
        font-size: 16px;
        margin: -2px 8px 0 8px;
        white-space: nowrap;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
      h1, h2 {
        color: #ffffff;
        display: inline-block;
        font-weight: 600;
        line-height: 1em;
      }
    }
    .menu {
      padding: 0;
      .ant-menu-item, .ant-menu-submenu-title{
        line-height: 50px;
        height: 50px;
      }
      .ant-menu-inline.ant-menu-sub{
        background: rgba(0,0,0,.19);
        box-shadow: none;
      }
    }
    .organizationName{
      background-color: #522e56;
      border-bottom: 3px solid #8e4896;
      padding: 14px;
      color: #f3e3e7;
    }
  }
  .ant-menu-dark, .ant-menu-dark .ant-menu-sub{
    background-color: @admin-layout-sidebar;
  }
  .ant-layout-sider{
    background: @admin-layout-sidebar; // #482e59
    .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
      background: rgba(0,0,0, .3);
    }
    .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .admin-header {
    font-size: 20px;
    border-bottom: 1px solid #e4e7ea;
    
    z-index: 1000;
    .anticon {
     line-height: 64px;
     padding: 0 24px;
    }
    .ant-menu {
      margin-right: 24px;
      border-bottom: 0px;
      float: right;
      .ant-menu-item {
        border-bottom: none;
      }
    }
    .ant-menu-submenu, .ant-menu-submenu:hover{
      border-bottom: 0px;
    }
  }
}

.notifications{
  .ant-menu.ant-menu-sub{
    padding: 0px 5px; 
    .no-link{
      border-radius: 3px;
      background-color: #332e59; 
      color: #cac6e9;
      &:hover{
        background-color: #4e4879;
        color: #fff;
      }
      &.viewed{
        background-color: transparent; 
        color: rgba(0, 0, 0, 0.65);
        &:hover{
          color: #127ed6;
        }
      }
    }
  }
}

.ant-layout-content.full-height{
  position: absolute;
  top: 88px;
  bottom: 24px;
  right: 24px;
  left: 250px;
  border: 0px;
}

label{
  font-weight: bold;
}