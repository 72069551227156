@import "./variables.less";
@import './reset.less';


.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
//  border-left: 1px solid #514d6f
  // margin: -24px -32px;
}
// @media only screen and (max-width: 1436px) {
//   .rbc-calendar {
//     margin: -24px;
//   }
// }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: @out-of-range-color;
}

.rbc-off-range-bg {
  background: @out-of-range-bg-color;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 13px 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid @cell-border;

  & + & {
    border-left: 1px solid @cell-border;
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    border-right: 1px solid @cell-border;
  }

  & > a {
    &, &:active, &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-content {
  position: relative;
  patient-select: none;
  -webkit-patient-select: none;
  z-index: 4;
}

.rbc-today {
  background-color: @today-highlight-bg;
}
.rbc-time-header .rbc-time-header-gutter{
  background-color: #4e4879;
}
@import './toolbar.less';
@import './event.less';
@import './month.less';
@import './agenda.less';
@import './time-grid.less';
.rbc-time-content{
  border-top: 2px solid #7e7aa2;
  border-right: 1px solid #7e7aa2;
  border-bottom: 0px; //1px solid #7e7aa2;
}
.rbc-time-view {
  border: 0px;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #7e7aa2;
}
