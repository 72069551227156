.login-page {
  padding: 112px 0 24px;
  min-height: 100%;
  .background {
    position: fixed; 
    top: 0; 
    left: 0; 
    min-width: 100%;
    min-height: 100%;
    // z-index: 1;
  }
  .ant-card.login-form.ant-card-bordered{
    border-top: 15px solid rgb(255, 102, 0);
  }
  .login-form {
    .header {
      margin: 0 0 24px 0;
      .logo{
        color: rgb(255, 102, 0);
        line-height: 2em;
        vertical-align: middle;
        .n{
          border-top: 3px solid rgb(255, 102, 0);
          border-bottom: 3px solid rgb(255, 102, 0);
          font-size: .8em;
          margin-right:10px;
        }
      }
    }
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    .ant-input {
      min-height: 0;
    }
  }
  .passwordText{
    text-align: center;
    ul{
      text-align: left;
      width: 160px;
      display: inline-block;
      li{
        height: 20px
      }
    }
    label{
      color: red;
      display: block;
    }
  }
}