.scroller{
    position: relative;
    height: 100%;
    .scroller-body{
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
    }
}