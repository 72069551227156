@import "~antd/dist/antd.less";

// https://ant.design/docs/react/customize-theme
@primary-color: #127ed6; //#0f9967;

body {
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
  .ant-input-affix-wrapper {
    .ant-input {
      min-height: 0;
    }
  }
  overflow: hidden;
}
.ant-input-suffix{
  margin: -4px -11px -4px 0px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid rgb(217, 217, 217);
}
#app{
  height: 100%;
  .ant-layout{
    height: 100%;
  }
}

.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}


.ant-table-thead > tr > th{
  background-color: #332e59;
  color: #fff;
}
.ant-table-tbody > tr:hover > td {
  background-color: #e9ebf0;
}


.ant-table-thead > tr:first-child > th:first-child, .ant-table-thead > tr:first-child > th:last-child{
  border-radius: 0px;
}


.ant-table-pagination.ant-pagination{
  margin-right: 16px
}

.ant-list-bordered{
  border-radius: 0px;
  &.border-center-only{
    border-right: 0px;
    border-left: 0px;
  }
}

.ant-card.no-padding.ant-card-bordered .ant-card-body{
  padding: 0px;
}
.ant-card.full-height{
  height: 100%;
}

.ant-pull-right{
  float: right;
}

.ant-tabs-nav-container{
  margin-left: 28px;
}


.ant-select-item-option-content{
    cursor: default;
    .ant-divider.ant-divider-horizontal{
        margin: 10px 0px 5px;
    }
}




/* FIX FOR SEARCH AHEAD */
.ant-select-auto-complete.ant-select .ant-select-selection__rendered{
  height: 32px;
}


.ant-form-item-with-help{
  margin-bottom: 12px; /* DON'T CHANGE THIS! */
}

.ant-steps-item-icon > .ant-steps-icon{
  line-height: 32px;
}

.ant-tabs.ant-tabs-top{
  height:100%;
  .ant-tabs-tab{
    margin: 0px;
    padding-right: 1em;
    padding-left: 1em;
  }
  .ant-tabs-bar{
    margin: 0px;
  }
  .ant-tabs-nav-wrap{
    height: 46px;
  }
}

.ant-tabs-content{
  height: 100%;
}

.pull-left{
  float: left;
}

.pull-right{
  float: right;
}
// .anticon.anticon-close{
//   margin-top: -5px;
// }
.ant-drawer{
  height: 100% !important;
}
.ant-drawer-body{
    padding-bottom: 60px;
}