.general-editor{
    .container{
        height: 100%;
        padding-bottom: 53px;
        width: 100%;
        padding-right: 18px;
        position: relative;
    }
    .footer{
        position: absolute;
        bottom: 0px;
        right: 0px;
        left: 0px;
        border-top: 1px solid #e8e8e8;
        height: 53px;
        padding: 10px; 
        background-color: #fff;
    }
}