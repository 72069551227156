.home-page {
  min-height: 100%;
  .ant-card {
    margin: 24px;
    h1 {
      margin: 0 0 12px 0;
    }
    p {
      margin: 0 0 12px 0;
    }
    img {
      margin: 12px 12px 0 0;
    }
  }
}